import type { JSX } from 'react';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

import { Blog } from '@/components';

export type BlogPostProps = SliceComponentProps<Content.BlogPostsSlice>;

const BlogPosts = ({ slice }: BlogPostProps): JSX.Element => {
  return <Blog {...slice} />;
};

export default BlogPosts;
